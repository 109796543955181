<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=-id&expand=goodsUnit,adjustQuantity,exWarehouseQuantity,warehouseQuantity,inventoryValue,averagePrice"
    entity-name="当前库存"
    hide-delete
    hide-show-detail
    show-row-num
    :rules="rules"
    :columns="columns"
    :search-key-type="searchKeyType"
    :before-edit="beforeEdit"
    :action="action"
  >
    <template v-slot:button>
      <a-button type="primary" @click="exportData" :loading="exporting">
        {{ exporting ? "正在导出" : "导出EXCEL" }}
      </a-button>
    </template>

    <template v-slot:query="{ form }">
      <a-form-model-item>
        <a-input v-model="form.name" placeholder="商品名称/SKU" allowClear></a-input>
      </a-form-model-item>

      <!-- <a-form-model-item>
        <a-input v-model="form.average_rate" placeholder="商品平均价" allowClear></a-input>
      </a-form-model-item>

      <a-form-model-item>
        <a-select
          placeholder="选择条件"
          v-model="form.price_condition"
          :options="priceConditionOptions"
          style="width: 180px;"
        ></a-select>
      </a-form-model-item> -->
    </template>

    <template v-slot:edit="{ form }">
      <a-form-model-item label="商品名称" prop="name" v-if="form.name">
        <a-input v-model="form.name" placeholder="请输入商品名称" allowClear disabled></a-input>
      </a-form-model-item>
      <a-form-model-item :label="`当前库存：${form.old_goods_num}`" prop="goods_num" extra="调整后的商品库存">
        <a-input-number
          v-model="form.goods_num"
          placeholder="请输入库存"
          :min="0"
          :step="1"
          style="width: 100%"
          allowClear
        ></a-input-number>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
import { priceConditionOptions } from "../../common/asset/type";
import { getTemple } from "../../common/js/storage";
import { downLoadBlobFile, getQuery } from "../../common/js/tool";

export default {
  name: "InventoryList",
  data() {
    return {
      url: "/admin/asset-goods",
      searchKeyType: {
        name: 2,
      },
      columns: [
        { title: "SKU", dataIndex: "sku" },
        { title: "商品名称", dataIndex: "name" },
        {
          title: "单位",
          customRender: (text) => {
            return text?.goodsUnit?.name || "-";
          },
        },
        { title: "平均价(元)", dataIndex: "averagePrice" },
        { title: "库存价值(元)", dataIndex: "inventoryValue" },
        { title: "库存数量", dataIndex: "goods_num" },
        { title: "入库数量", dataIndex: "warehouseQuantity" },
        { title: "出库数量", dataIndex: "exWarehouseQuantity" },
        { title: "调整数量", dataIndex: "adjustQuantity" },
      ],
      rules: {
        name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        goods_num: [{ required: true, message: "请输入库存", trigger: "change" }],
      },
      priceConditionOptions,
      exporting: false,
    };
  },
  computed: {
    dataList() {
      return this.$refs.dataList;
    },
    action() {
      return this.$route.meta.action;
    },
  },
  created() {},
  methods: {
    beforeEdit(form, fn) {
      form.old_goods_num = form.goods_num || 0;
      fn(form);
    },
    // 导出EXCEl
    exportData() {
      if (this.exporting) return;
      this.exporting = true;
      let temple = getTemple();
      let url = `${this.url}/export?temple_id=${temple}` + getQuery.call(this.$refs.dataList, this.searchKeyType);
      this.$axios(url, { noTempleFilter: true, responseType: "blob" })
        .then((res) => {
          downLoadBlobFile(res, "库存数据");
        })
        .finally(() => {
          this.exporting = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  color: #909090;
  font-size: 12px;
  margin-top: 8px;
}
</style>
